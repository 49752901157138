*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 5s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-line-break {
  display: block;
  margin-bottom: 16px;
}

.isi-hadits {
  text-indent: -0.6rem;
}

.webkit-box-shadow-secondary {
  box-shadow: 0px 8px 24px rgba(65, 201, 226, 0.2); /* Equivalent to #41c9e233 */
  -webkit-box-shadow: 0px 8px 24px rgba(65, 201, 226, 0.2); /* Safari fix */
}
